<template>
  <en-table-layout
    :pagination="false"
    :tableData="pageData.data"
    :loading="loading"
  >
    <template slot="toolbar">
      <div class="col-auto toolbar-title">
        日常数据
      </div>

      <el-date-picker
        v-model="time"
        type="month"
        size="medium"
        @change="daterangeDateChange"
        placeholder="请选择月份"
      />

      <div class="col"></div>

      <el-button type="text" :loading="importLoading" @click="downZd()">导出当前数据</el-button>
    </template>

    <template slot="table-columns">
      <el-table-column label="序号">
        <template slot-scope="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column label="日期">
        <template slot-scope="scope">{{ scope.row.date }}</template>
      </el-table-column>
      <el-table-column label="PV">
        <template slot-scope="scope">{{ scope.row.pvNum }}</template>
      </el-table-column>
      <el-table-column label="UV">
        <template slot-scope="scope">{{ scope.row.uvNum }}</template>
      </el-table-column>
      <el-table-column label="新增用户">
        <template slot-scope="scope">{{ scope.row.addMemberNum }}</template>
      </el-table-column>
      <el-table-column label="访客">
        <template slot-scope="scope">{{ scope.row.visitNum }}</template>
      </el-table-column>
      <el-table-column label="下单数">
        <template slot-scope="scope">{{ scope.row.orderNum }}</template>
      </el-table-column>
      <el-table-column label="消耗金额">
        <template slot-scope="scope">{{ scope.row.payMoney }}</template>
      </el-table-column>
    </template>
  </en-table-layout>
</template>

<script>
	import * as API_buyAnyalysis from '@/api/buyAnyalysis'
	import { CategoryPicker } from '@/components'
	import { handleDownload } from '@/utils'
  import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
	export default {
		name: 'buyAnalysis',
		components: {
      EnTableLayout,
			[CategoryPicker.name]: CategoryPicker
		},
		data() {
			return {
				importLoading: false,
				loading: false,
        pageData: {data: []},
				params: {},
				time: new Date(),
				today: null
			}
		},
		created() {
			this.GET_PriceStatistics()
		},
		mounted() {
			const end = new Date();
			this.params.year = end.getFullYear()
			this.params.month = end.getMonth() + 1
		},
		methods: {
			downZd(){
				this.importLoading = true
				let tHeaders = ['日期', 'PV', 'UV', '新增用户', '访客', '下单数', '消耗金额']
				let filterVals = ['date', 'pvNum', 'uvNum', 'addMemberNum', 'visitNum', 'orderNum', 'payMoney']
				handleDownload(this.pageData.data, tHeaders, filterVals, '日常数据')
				this.importLoading = false
			},
			daterangeDateChange(val){
				console.log(val);
				console.log(val.getFullYear());
				console.log(val.getMonth());
				this.params.year = val.getFullYear()
				this.params.month = val.getMonth() + 1
				this.handleSearch()
			},
			handleSearch() {
				this.GET_PriceStatistics()
			},
			GET_PriceStatistics() {
				this.loading = true
				API_buyAnyalysis.orderDayData(this.params).then(response => {
					this.pageData.data = response
					this.loading = false
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.tipBox{background: #fff;margin-bottom: 10px;padding: 20px;border-radius: 5px;}
	.tipBox p{margin: 0;line-height: 18px;margin-bottom: 15px;font-size: 14px;}

	.conditions{display: inline-block;margin-right: 20px;}
	.conditions.right{float: right;}
	.conditions >>> .el-input{display: inline-block;}
	.conditions span{font-size: 14px;color: #606266;}

	.btnTab{margin-top: 20px;margin-bottom: -30px;position: relative;z-index: 1;width: 500px;}
	.tipBox p{line-height: 24px;padding: 0;position: relative;padding-left: 18px;font-size: 24px;color: #393c41;margin-bottom: 30px;}
	.tipBox p:before{content: ' ';display: block;width: 8px;height: 24px;background: #1a43a9;position: absolute;left: 0;top: 0;}
</style>

